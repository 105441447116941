import { memo } from 'react';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import { colors } from 'src/theme/palette/darkPalette';

const FilteredCountRow = ({ count, styles = {}, colSpan = 8 }) => (
  <TableRow>
    <TableCell
      colSpan={colSpan}
      sx={{
        backgroundColor: '#484848 !important',
        borderTopLeftRadius: '0px !important',
        borderTopRightRadius: '0px !important',
        padding: '0 0 8px',
        borderBottom: 'unset',
        ...styles,
      }}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        backgroundColor={!!count ? colors.darkGrey : 'unset'}
        color={colors.green}
        sx={{
          width: 'fit-content',
          minHeight: '24px',
          ml: 'auto',
          p: '0 8px',
          minWidth: '80px',
          borderRadius: 1,
          fontSize: '14px',
        }}>
        {count !== null ? `Filtered (${count})` : null}
      </Stack>
    </TableCell>
  </TableRow>
);

export default memo(FilteredCountRow);
