import { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'src/components/Button';
import DeleteButton from 'src/components/DeleteButton';

const TagChangeModal = ({ open, onClose, onSubmit }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        maxWidth: '450px',
      },
    }}>
    <DialogTitle>Are you sure you want to change the device tags?</DialogTitle>
    <DialogContent>
      <Typography
        sx={{
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: 0.25,
          fontWeight: 400,
        }}>
        The device tag will be deleted from the device tag list
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        styles={{
          color: '#B5B5B5',
        }}
        variant="text"
        onClick={onClose}>
        No
      </Button>
      <DeleteButton onClick={onSubmit}>Yes</DeleteButton>
    </DialogActions>
  </Dialog>
);

export default memo(TagChangeModal);
