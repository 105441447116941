import { DOWNLOADS_QUERY_KEY } from '../../constants/query';
import DownloadsService from '../../services/downloads.service';
import { useCallback } from 'react';
import useInfiniteFetch from '../useInfiniteFetch';
import { EXPANDED_ROWS_LIMIT } from 'src/constants/table';

const useFetchDownloads = ({ customerEnvId }) => {
  const fetchFunction = useCallback(
    ({ page, limit }) =>
      DownloadsService.getDownloadsResources({
        limit,
        page,
        customerEnvId,
      }),
    [customerEnvId],
  );

  const response = useInfiniteFetch({
    queryKey: [DOWNLOADS_QUERY_KEY, customerEnvId],
    fetchFunction,
    expandedRowsLimit: EXPANDED_ROWS_LIMIT,
    itemName: 'downloads',
  });

  return {
    ...response,
  };
};

export default useFetchDownloads;
