import api from './api';

const API_PREFIX = '/device';
const API_PREFIX_DASHBOARD = '/dashboard';

const getDevice = ({ deviceId, customerEnvId }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/${deviceId}`);

const getDevices = ({ limit, page, customerEnvId, query }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/all${query}`, {
    params: {
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const updateStatus = ({ deviceId, status }) =>
  api.put(`${API_PREFIX}`, {
    status,
    deviceId,
  });

const getDeviceDashboardEnforcement = ({ customerEnvId, TagIds, startDate, endDate }) =>
  api.get(`${API_PREFIX_DASHBOARD}/${customerEnvId}/enforcementStatusData?TagIds=${TagIds}`, {
    params: {
      // ...(TagNames ? { TagNames } : {}),
      ...(startDate ? { LastWdacHeartbeatStartDate: startDate } : {}),
      ...(endDate ? { LastWdacHeartbeatEndDate: endDate } : {}),
    },
    paramsSerializer: params => {
      return Object.entries(params)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return value.map(v => `${key}=${v}`).join('&');
          }
          return `${key}=${value}`;
        })
        .join('&');
    },
  });

const getDeviceDashboardHealth = ({ customerEnvId, TagIds, startDate, endDate }) =>
  api.get(`${API_PREFIX_DASHBOARD}/${customerEnvId}/deviceHealthData?TagIds=${TagIds}`, {
    params: {
      // ...(TagNames ? { TagNames } : {}),
      ...(startDate ? { LastWdacHeartbeatStartDate: startDate } : {}),
      ...(endDate ? { LastWdacHeartbeatEndDate: endDate } : {}),
    },
    paramsSerializer: params => {
      return Object.entries(params)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return value.map(v => `${key}=${v}`).join('&');
          }
          return `${key}=${value}`;
        })
        .join('&');
    },
  });

const getOsTypes = ({ customerEnvId, search, limit, page }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/osTypes`, {
    params: {
      search,
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const getOsSku = ({ customerEnvId, search, limit, page }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/osSku`, {
    params: {
      search,
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const getOsVersions = ({ customerEnvId, search, limit, page }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/osVersions`, {
    params: {
      search,
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const getLogCollectorVersions = ({ customerEnvId, search, limit, page }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/logCollectorVersions`, {
    params: {
      search,
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const DeviceService = {
  getDevice,
  getDevices,
  updateStatus,
  getDeviceDashboardEnforcement,
  getDeviceDashboardHealth,
  getOsTypes,
  getOsSku,
  getOsVersions,
  getLogCollectorVersions,
};

export default DeviceService;
