import React, { memo } from 'react';
import { Cell, Tooltip, BarChart, Bar, CartesianGrid, Text, XAxis, YAxis } from 'recharts';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { COLORS } from './constants';
import LegendRow from './LegendRow';
import { Loader } from 'src/components';
import './styles.css';

const DeviceChart = ({ startChildren, title, data, onItemClick, isLoading }) => {
  const maxValue = Math.max(...data.map(entry => entry.value));
  const ticks = Array.from({ length: maxValue + 1 }, (_, i) => i);

  return (
    <Stack
      gap={1}
      flex={1}
      // justifyContent="end"
      sx={{
        backgroundColor: '#454545',
        borderRadius: 4,
        padding: 2,
      }}>
      {startChildren}
      <Typography
        sx={theme => ({
          fontSize: theme.typography.pxToRem(24),
          fontWeight: 'bold',
          textAlign: 'center',
          mt: 'auto',
        })}>
        {title}
      </Typography>

      {isLoading && (
        <Stack width="100%" justifyContent="center" height={'192px'}>
          <Loader />
        </Stack>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: 600,
          margin: '0 auto',
        }}>
        <Stack direction="row" alignItems="center">
          {!isLoading && !data.length && (
            <Typography
              sx={theme => ({
                fontSize: theme.typography.pxToRem(18),
                fontWeight: '300',
                textAlign: 'center',
                height: '200px',
                pt: '68px',
              })}>
              No data
            </Typography>
          )}

          {!!data.length && (
            <>
              {' '}
              <BarChart data={data} width={330} height={200}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="label"
                  fill="#fff"
                  stroke="#fff"
                  tickSize={2}
                  tick={event => (
                    <Text
                      {...event}
                      style={{
                        fontSize: '12px',
                      }}>
                      {event.payload.value}
                    </Text>
                  )}
                />
                <YAxis ticks={ticks} domain={[0, 'dataMax']} />
                <Bar
                  onClick={data => {
                    onItemClick(data);
                  }}
                  cursor={'pointer'}
                  barSize={50}
                  dataKey="value"
                  minPointSize={10}>
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      style={{ display: entry.value === 0 ? 'none' : 'block' }}
                    />
                  ))}
                </Bar>
                <Tooltip cursor={{ fill: 'transparent' }} />
              </BarChart>
              <Stack justifyContent="center">
                {data.map(entry => {
                  if (entry.value === 0) return null;

                  return (
                    <LegendRow
                      key={entry.name}
                      color={COLORS[data.indexOf(entry) % COLORS.length]}
                      title={entry.label}
                      onClick={() => onItemClick(entry)}
                    />
                  );
                })}
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default memo(DeviceChart);
