import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import { forwardRef, useCallback, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import Iconify from '../../components/Iconify';
import { datePickerOptions } from './constants';
import { colors } from '../../theme/palette/darkPalette';
import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';
import { getDateSelectedDayAgo } from '../../utils/date.util';

const DatePicker = ({
  initialStartDate,
  initialEndDate,
  onChangeDateRange,
  initialDateRange = 5,
  shouldShowRecentDays = true,
  shouldShowDateTitle = true,
  ...props
}) => {
  const [visibilityDays, setVisibilityDays] = useState(initialDateRange);
  const [isOpen, setIsOpen] = useState(false);
  const isDateChanged = useRef(false);
  const pickerRef = useRef(null);
  const [[startDate, endDate], setDateRange] = useState([
    shouldShowRecentDays ? initialStartDate || getDateSelectedDayAgo(5) : initialStartDate,
    shouldShowRecentDays ? initialEndDate || new Date() : initialEndDate,
  ]);

  useOutsideClickHandler(
    null,
    () => {
      setIsOpen(false);
    },
    [
      'react-datepicker',
      'react-datepicker__day',
      'react-datepicker__header',
      'month-year-title',
      'prev-button',
      'next-button',
      'recent-days-select',
      'recent-day-option',
      'date-input-wrapper',
    ],
    true,
  );

  const handleSetVisibilityDays = useCallback(
    days => {
      setVisibilityDays(days);
      const newStartDate = getDateSelectedDayAgo(days);
      setDateRange([newStartDate, new Date()]);
      pickerRef.current.setPreSelection(newStartDate);
      isDateChanged.current = true;
    },
    [setVisibilityDays, setDateRange],
  );

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Stack direction="row" alignItems="center" id="date-input-wrapper">
      {shouldShowDateTitle && (
        <Typography
          sx={{
            fontSize: '16px',
            marginLeft: '-16px !important',
          }}>
          {value}
        </Typography>
      )}
      <IconButton
        onClick={() => {
          onClick();
          setIsOpen(true);
        }}>
        <Iconify ref={ref} icon="solar:calendar-outline" color="#fff" />
      </IconButton>
    </Stack>
  ));

  const CustomCalendarContainer = useCallback(
    ({ className, children }) => (
      <CalendarContainer id="container" className={className}>
        {children}
        {shouldShowRecentDays && (
          <Stack gap={1} p={1}>
            {datePickerOptions.map(option => (
              <Button
                size="small"
                id="recent-day-option"
                key={option.value}
                onClick={() => handleSetVisibilityDays(option.value)}
                sx={{
                  textTransform: 'capitalize',
                  backgroundColor: visibilityDays === option.value ? colors.green : 'transparent',
                  color: visibilityDays === option.value ? '#000' : '#fff',
                  '&:hover': {
                    backgroundColor: colors.green,
                    color: '#000',
                  },
                }}>
                {option.label}
              </Button>
            ))}
          </Stack>
        )}
      </CalendarContainer>
    ),
    [visibilityDays, shouldShowRecentDays, handleSetVisibilityDays],
  );

  return (
    <ReactDatePicker
      {...props}
      open={isOpen}
      showPopperArrow={false}
      selectsRange={true}
      popperPlacement="bottom-start"
      customInput={<CustomInput />}
      calendarContainer={CustomCalendarContainer}
      startDate={startDate}
      endDate={endDate}
      ref={pickerRef}
      onCalendarClose={() => {
        setIsOpen(false);
        if (!isDateChanged?.current) return;

        onChangeDateRange([startDate, endDate]);
      }}
      onChange={update => {
        setDateRange(update);
        if (isDateChanged?.current) return;

        isDateChanged.current = true;
      }}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 4px',
            }}>
            <IconButton id="prev-button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <Iconify icon="bi:chevron-left" width={16} height={16} color="#fff" />
            </IconButton>
            <Typography id="month-year-title" sx={{ color: '#fff' }}>
              {format(new Date(date), 'MMMM yyyy')}
            </Typography>
            <IconButton id="next-button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <Iconify icon="bi:chevron-right" width={16} height={16} color="#fff" />
            </IconButton>
          </Box>
        </Box>
      )}
    />
  );
};

export default DatePicker;
