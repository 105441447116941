import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { pagesText } from '../../constants/pagesText';
import DeviceService from '../../services/device.service';

const useMutateDeviceDetailsStatus = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(DeviceService.updateStatus, {
    onError: () => {
      enqueueSnackbar(pagesText.somethingWentWrong, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
    onSuccess: () => {
      enqueueSnackbar(pagesText.devices.successFullyUpdateDeviceStatus, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
  });
};

export default useMutateDeviceDetailsStatus;
