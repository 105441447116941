import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import TagService from '../../services/tag.service';
import { DEVICE_QUERY_KEY, DEVICE_TAGS_QUERY_KEY, DEVICES_QUERY_KEY } from '../../constants/query';
import { pagesText } from '../../constants/pagesText';

const useRemoveListTag = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(TagService.removeListTag, {
    onError: (err, newData, context) => {
      queryClient.setQueryData([DEVICE_TAGS_QUERY_KEY, newData.search], context.previousTags);
      queryClient.setQueryData(
        [DEVICES_QUERY_KEY, newData.search, newData.tagId],
        context.previousDevices,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([DEVICE_QUERY_KEY]);
      await queryClient.invalidateQueries([DEVICES_QUERY_KEY]);

      enqueueSnackbar(pagesText.devices.editTagModal.removeTagMessage, { variant: 'success' });
    },
  });
};

export default useRemoveListTag;
