import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import DownloadsService from '../../services/downloads.service';
import { DOWNLOADS_QUERY_KEY } from '../../constants/query';
import { pagesText } from '../../constants/pagesText';
import IconButton from '../../components/IconButton';
import Iconify from '../../components/Iconify';
import useUpdateResourceStatus from '../useUpdateResourceStatus';
import { filterObjectArray } from '../../utils/helpers';

const useFetchDownloadResource = ({ onChangeNotificationBadgeVisibility, customerEnvId }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { updateResourceStatus } = useUpdateResourceStatus();

  const applyNotification = useCallback(
    (notification, resources) => {
      const snackbarId = enqueueSnackbar(pagesText.notifications.readyToDownload, {
        variant: 'success',
        autoHideDuration: 5000,
        action: snackbarId => (
          <IconButton
            onClick={() => {
              closeSnackbar(snackbarId);
              updateResourceStatus({
                downloadId: notification.id,
                resources,
              });
              onChangeNotificationBadgeVisibility(false);
            }}>
            <Iconify icon="ic:round-check" color="#fff" width={24} height={24} />
          </IconButton>
        ),
        preventDuplicate: true,
      });
      queryClient.setQueryData([DOWNLOADS_QUERY_KEY, customerEnvId], prevData => ({
        ...prevData,
        ...{
          ...notification,
          snackbarId,
        },
      }));
    },
    [
      updateResourceStatus,
      closeSnackbar,
      customerEnvId,
      enqueueSnackbar,
      onChangeNotificationBadgeVisibility,
      queryClient,
    ],
  );

  return useMutation(DownloadsService.getDownloadResourceStatus, {
    onSuccess: updatedData => {
      queryClient.setQueryData([DOWNLOADS_QUERY_KEY, customerEnvId], prevData => {
        if (!prevData || !prevData.pages) return prevData;

        const newData = {
          ...prevData,
          pages: prevData.pages.map(page => {
            const updatedDownloads = page.downloads.map(download => {
              if (download.id === updatedData.id) {
                return { ...download, ...updatedData };
              }
              return download;
            });
            return { ...page, downloads: updatedDownloads };
          }),
        };

        if (updatedData.status === 'ready') {
          const flatDownloads = newData.pages.flatMap(page => page.downloads);
          const filteredDownloads = filterObjectArray(flatDownloads, 'type');
          onChangeNotificationBadgeVisibility(false);
          applyNotification(updatedData, filteredDownloads);
        }

        if (updatedData.status === 'failed') {
          enqueueSnackbar(pagesText.notifications.downloadFailed, {
            variant: 'error',
            autoHideDuration: 5000,
          });
        }

        return newData;
      });
    },
  });
};

export default useFetchDownloadResource;
