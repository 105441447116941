import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableHeading from './TableHeading';
import Row from './Row';
import FiltersSidebar from '../Filters';
import { pagesText } from '../../constants/pagesText';
import LoadingRows from './LoadingRows';
import useQueryParams from '../../hooks/useQueryParams';
import SearchTableWrapper from '../SearchTableWrapper';
import useDebounceHandler from '../../hooks/useDebounceHandler';
import NoDataCell from '../NoDataCell';
import { defaultAppsFilters } from '../Filters/constants';
import useTableSort from '../../hooks/useTableSort';
import { checkHasFilters } from '../../utils/table.util';
import useResetSearchParams from '../../hooks/useResetSearchParams';
import { PORTAL_ROUTE } from '../../constants/routes';
import TableContainer from './TableContainer';
import SearchInputRow from '../SearchInputRow';
import useFetchApps from '../../hooks/useFetchApps';
import FilteredCountRow from '../FilteredCountRow';

export const sortingMethods = {
  asc: 'asc',
  desc: 'desc',
  initial: 'initial',
};

const reversedSortMap = {
  [sortingMethods.asc]: sortingMethods.desc,
  [sortingMethods.desc]: sortingMethods.asc,
};

const Table = ({ headings, columnsOrder }) => {
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const navigate = useNavigate();
  useResetSearchParams();

  const { data, lastElementRef, isLoading, isFetching, applicationsCount } = useFetchApps({});
  const {
    queryObject: { filters = {}, searchValue: initialSearchValue },
    onApplySearchParams,
    onResetSearchParams,
    changeFieldInURL,
  } = useQueryParams();
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const { onSort, onSortTable, onResetSortField, getListItemSortMethod, getCellSortMethod } =
    useTableSort({
      filters,
      onApplySearchParams,
      searchValue,
      defaultFilters: defaultAppsFilters,
    });

  const debounceQueryHandler = useDebounceHandler((key, value) => {
    changeFieldInURL([{ key, value }]);
  }, 500);

  const onChangeSearchValue = useCallback(
    event => {
      setSearchValue(event.target.value);
      debounceQueryHandler('search', event.target.value);
    },
    [debounceQueryHandler, setSearchValue],
  );

  const hasFilters = useMemo(() => checkHasFilters(filters), [filters]);

  const onApplyFilters = useCallback(
    filters => {
      onApplySearchParams({ filters, searchValue });
    },
    [onApplySearchParams, searchValue],
  );

  const onOpenFilters = useCallback(() => {
    setIsOpenFilters(true);
  }, [setIsOpenFilters]);

  const onCloseFilters = useCallback(() => {
    setIsOpenFilters(false);
  }, [setIsOpenFilters]);

  const onResetSearch = useCallback(() => {
    onResetSearchParams();
    setSearchValue('');
  }, [onResetSearchParams, setSearchValue]);

  const onCellSort = useCallback(
    ({ field, sortingCellField, sortMethod }) => {
      const reversedSortOrder =
        sortMethod === sortingMethods.initial ? sortingMethods.asc : reversedSortMap[sortMethod];
      onSort(field, reversedSortOrder, sortingCellField);
    },
    [onSort],
  );

  const navigateToApp = useCallback(id => {
    navigate(`${PORTAL_ROUTE}/apps/${id}/general`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredCount = useMemo(() => {
    return !isLoading && (hasFilters || searchValue) ? applicationsCount : null;
  }, [applicationsCount, hasFilters, isLoading, searchValue]);

  return (
    <SearchTableWrapper>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <TableContainer square component={Paper} elevation={0}>
          <MuiTable stickyHeader aria-label="collapsible table">
            <TableHeading
              hasFilters={hasFilters}
              onOpenFilters={onOpenFilters}
              headings={headings}
              onSortTable={onSortTable}
              onCellSort={onCellSort}
              onResetSortField={onResetSortField}
              getListItemSortMethod={getListItemSortMethod}
              getCellSortMethod={getCellSortMethod}
              listCellStyles={{
                top: '86px',
              }}>
              <FilteredCountRow
                count={filteredCount}
                styles={{
                  backgroundColor: '#333333 !important',
                }}
              />
              <SearchInputRow
                searchValue={searchValue}
                onChangeSearchValue={onChangeSearchValue}
                onResetSearch={onResetSearch}
                placeholder="Enter a keyword"
                cellStyles={{
                  top: '30px',
                }}
              />
            </TableHeading>
            <TableBody>
              {isLoading ? (
                <LoadingRows columnCount={columnsOrder.length + 1} rowCount={5} />
              ) : (
                <>
                  {data.map((row, index) => (
                    <Row
                      ref={data.length - 1 === index ? lastElementRef : null}
                      key={row.id}
                      row={row}
                      columnData={columnsOrder}
                      rowClickHandler={navigateToApp}
                      isClickable
                      source="apps"
                    />
                  ))}
                  {!data.length && <NoDataCell>{pagesText.noSearchResult}</NoDataCell>}
                  {isFetching && !isLoading && (
                    <LoadingRows columnCount={columnsOrder.length + 1} rowCount={5} />
                  )}
                </>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
        <FiltersSidebar
          open={isOpenFilters}
          onClose={onCloseFilters}
          onApplyFilters={onApplyFilters}
          initialQueryFilters={filters}
          source="app"
        />
      </Paper>
    </SearchTableWrapper>
  );
};

export default Table;
